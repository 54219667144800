var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"panel"},[_vm._m(0),_c('div',{staticClass:"panel-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 mb-4"},[_c('GmapMap',{staticClass:"bg-black height-sm",attrs:{"zoom":13,"center":{
                  lat: Number(_vm.form.lat_lokasi),
                  lng: Number(_vm.form.long_lokasi),
                }}},_vm._l((_vm.markers),function(marker,index){return _c('GmapMarker',{key:index,attrs:{"position":marker.position,"clickable":true,"draggable":true},on:{"dragend":_vm.handleMarkerDragEnd}})}),1)],1),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"f-w-600",attrs:{"for":"id_area"}},[_vm._v("Nama Area ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.id_area),expression:"form.id_area"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.id_area != '',
                    'is-invalid': _vm.formValidate.id_area || _vm.form.id_area == '',
                  },attrs:{"type":"text","required":""},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "id_area", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.formValidate.id_area = ''}]}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("-Pilih-")]),_vm._l((_vm.listMasterArea),function(area){return _c('option',{key:area.id_area,domProps:{"value":area.id_area}},[_vm._v(" "+_vm._s(area.nama_area)+" ")])})],2)])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"f-w-600",attrs:{"for":"nama_lokasi"}},[_vm._v("Nama Lokasi ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nama_lokasi),expression:"form.nama_lokasi"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.nama_lokasi != '',
                    'is-invalid':
                      _vm.formValidate.nama_lokasi || _vm.form.nama_lokasi == '',
                  },attrs:{"type":"text","placeholder":"Nama Lokasi","required":""},domProps:{"value":(_vm.form.nama_lokasi)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "nama_lokasi", $event.target.value)},function($event){_vm.formValidate.nama_lokasi = ''}]}})])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"f-w-600",attrs:{"for":"lat_lokasi"}},[_vm._v("Latitude Lokasi ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.lat_lokasi),expression:"form.lat_lokasi"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.lat_lokasi != '',
                    'is-invalid':
                      _vm.formValidate.lat_lokasi || _vm.form.lat_lokasi == '',
                  },attrs:{"type":"text","placeholder":"Latitude Lokasi","required":""},domProps:{"value":(_vm.form.lat_lokasi)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "lat_lokasi", $event.target.value)},function($event){_vm.formValidate.lat_lokasi = ''}]}})])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"f-w-600",attrs:{"for":"long_lokasi"}},[_vm._v("Longitude Lokasi ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.long_lokasi),expression:"form.long_lokasi"}],staticClass:"form-control",class:{
                    'is-valid': _vm.form.long_lokasi != '',
                    'is-invalid':
                      _vm.formValidate.long_lokasi || _vm.form.long_lokasi == '',
                  },attrs:{"type":"text","placeholder":"Longitude Lokasi","required":""},domProps:{"value":(_vm.form.long_lokasi)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.form, "long_lokasi", $event.target.value)},function($event){_vm.formValidate.long_lokasi = ''}]}})])])])]),_c('div',{staticClass:"panel-footer"},[_c('button',{staticClass:"btn btn-success pull-right",attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":_vm.postData}},[(_vm.loading)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_vm._e(),_vm._v(" Submit ")]),_c('a',{staticClass:"btn btn-default",attrs:{"href":"javascript:window.history.go(-1);"}},[_vm._v("Kembali")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-heading bg-dark text-light"},[_c('h3',{staticClass:"panel-title bold"},[_vm._v("Form Lokasi")])])
}]

export { render, staticRenderFns }